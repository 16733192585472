// External library imports
import React, { useState } from 'react';
import { Tabs } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove, horizontalListSortingStrategy, SortableContext, useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';

// Internal component and function imports
import { setActivePoliciesTab } from '../../../../../store/appSlice';
import { getAggrPolicy, getNormalPolicy } from '../../../../../utils/template';
import Policy from './form/Policy';


const DraggableTabNode = ({className, onActiveBarTransform, ...props}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting
  } = useSortable({
    id: props['data-node-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  React.useEffect(() => {
    if (!isSorting) {
      onActiveBarTransform('');
    } else if (className?.includes('ant-tabs-tab-active')) {
      onActiveBarTransform(
        css`
          .ant-tabs-ink-bar {
            transform: ${CSS.Transform.toString(transform)};
            transition: ${transition} !important;
          }
        `,
      );
    }
  }, [className, isSorting, transform]);

  return React.cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

const Policies = ({aggregation, policies, variables, setPolicies, setAggregation }) => {
  const initialItems = [];
  const [activeKey, setActiveKey] = useState(0);
  const [items, setItems] = useState(initialItems);
  const [aggrPolicyAdded, setAggrPolicyAdded] = React.useState(false)
  const [className, setClassName] = useState('');

  const dispatch = useDispatch()
  const activeTab = useSelector(state => state.app.activePoliciesTab)
  const sensor = useSensor(PointerSensor, {activationConstraint: {distance: 10}});

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
    dispatch(setActivePoliciesTab(newActiveKey))
  };

  const add = () => {
    const id = uuidv4();
    let allPolicies = [...policies];
    let newPolicy = {
      id,
      tree_state: null,
      cost_bar_title: '',
      pos: items.length + 1,
      type: 'normal',
    };

    if (policies[0] && policies[0].type !== 'aggregation') {
      newPolicy = Object.assign({}, policies[0], newPolicy);
    } else {
      newPolicy = getNormalPolicy(newPolicy)
    }

    allPolicies.push(newPolicy);
    setPolicies(allPolicies);
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    setItems(newPanes);
    setActiveKey(newActiveKey);

    if (Object.keys(aggregation).length > 0 && aggregation.id === targetKey) {
      setAggrPolicyAdded(false)
      setAggregation({})
    }

    setPolicies(prevPolicies => prevPolicies.filter((item) => item.id !== targetKey));
  };
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const handlePolicyChange = (pol) => {
    const policyIndex = policies.findIndex(p => p.id === pol.id)
    setPolicies(prevPolicies => {
      prevPolicies[policyIndex] = pol

      return [...prevPolicies]
    })
  }

  const handleAggregationPolicyChange = (pol) => setAggregation({...pol});

  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      setPolicies((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const addAggregationPolicy = () => {
    setAggrPolicyAdded(true)
    let templatePolicy = {}
    if (policies && policies.length > 0) {
      templatePolicy.prioritization = policies[0].prioritization
    }
    setAggregation(getAggrPolicy(templatePolicy))
  }

  React.useEffect(() => {
    let firstKey = null;
    for (const policy of policies) {
      initialItems.push({
        label: policy.name,
        children: (
          <Policy
            policy={policy}
            policyType={'normal'}
            variables={variables}
            handlePolicyChange={(p) => handlePolicyChange(p)}/>
        ),
        key: String(policy.id),
      })

      if (firstKey === null) {
        firstKey = policy.id
      }
    }

    if (aggregation && Object.keys(aggregation).length > 0) {
      initialItems.push({
        label: aggregation.name,
        children: (
          <Policy
            policy={aggregation}
            policyType={'aggr'}
            variables={variables}
            handlePolicyChange={(p) => handleAggregationPolicyChange(p)}/>
        ),
        key: String(aggregation.id),
      })
      setAggrPolicyAdded(true)
    }

    setItems(initialItems);
    setActiveKey(String(activeTab ? activeTab : firstKey))
  }, [policies, aggregation])

  return (
    <>
      <Tabs
        className={className}
        style={{width: '100%'}}
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        renderTabBar={(tabBarProps, DefaultTabBar) => (
          <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext items={items.map((i) => i.key)}
                             strategy={horizontalListSortingStrategy}>
              <DefaultTabBar {...tabBarProps}>
                {(node) => (
                  <DraggableTabNode
                    {...node.props}
                    key={node.key}
                    onActiveBarTransform={setClassName}
                  >
                    {node}
                  </DraggableTabNode>
                )}
              </DefaultTabBar>
            </SortableContext>
          </DndContext>
        )}/>
      <Button
        sx={{marginTop:'10px'}}
        disabled={!(policies.length > 0 && !aggrPolicyAdded)}
        data-cy='btn-add-aggregation-policy'
        variant="contained"
        color="secondary"
        type="Submit"
        onClick={() => addAggregationPolicy()}>
        Add Aggregation Policy
      </Button>
    </>
  );
};

export default Policies;
