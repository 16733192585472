// External library imports
import * as React from 'react';
import {
  Typography, TextField, Button, Box, TextareaAutosize, Grid,
  Paper, MenuItem, FormControl, IconButton, Stepper, Step, StepLabel, StepContent,
  Autocomplete, FormControlLabel, Radio, RadioGroup
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CancelIcon from '@mui/icons-material/Cancel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Custom Hooks and Contexts
import { useTranslation } from '../../../../../providers/TranslationProvider';

// Internal component and function imports
import ModalDialogDragDrop from '../modal/ModalDialogDragDrop';
import ModalDialogCalcFormulaBuilder from '../modal/ModalDialogCalcFormulaBuilder';
import Statistics from './Statistics';
import Regimes from './Regimes';

// Style and asset imports
import {Item} from './styles/common';
import { ToolTip } from './styles/policy';
import * as PolicyStyle from './styles/policy';

export default function Policy({
  policy,
  variables,
  policyType,
  handlePolicyChange
}) {
  // Stepper Form
  const [activeStep, setActiveStep] = React.useState(0);
  const [numericalFields, setNumericalFields] = React.useState([])
  const [categoricalFields, setCategoricalFields] = React.useState([])
  const [factorFields, setFactorFields] = React.useState([])
  const [statColumns, setStatColumns] = React.useState([])
  const [sections, setSections] = React.useState([])
  const [notExistingAffectedVariables, setNotExistingAffectedVariables] = React.useState([])
  const targetCollection = useSelector(state => state.app.targetCollection)
  const [statsVarOptions, setStatsVarOptions] = React.useState([])

  const { t } = useTranslation();
  const groupItem = [
    {
      value: false,
      label: 'Prioritize high values',
      disabled: false
    },
    {
      value: true,
      label: 'Prioritize low values',
      disabled: false
    }
  ];

  React.useEffect(() => {
    const affected_variables = policy.affected_variables || [];

    let multi_policy_formulas = [];
    if (policyType === 'aggr') {
      multi_policy_formulas = policy.multi_policy_formulas ? policy.multi_policy_formulas : [];
    }

    setStatsVarOptions(Array.from(new Set([
      ...numericalFields.map(d => d.propName), // prioritization variable,
      ...statColumns.map(s => s.propName), // any statistic variable from the dataset
      ...affected_variables.map(d => d.name),// affected variables
      ...multi_policy_formulas.map(d => d.name) // multi-policy aggregation variables
    ])));
  }, [numericalFields, policy.multi_policy_formulas, policy.affected_variables, statColumns])

  const affectedVars = React.useMemo(() => {
    return policy.affected_variables;
  }, [policy.affected_variables])

  const segmentationVariablesOptions = React.useMemo(() => {
    let vars = [...categoricalFields]

    if (policy.segmentation_variables.dataset_custom) {
      vars = [
        ...vars,
        ...Object.keys(policy.segmentation_variables.dataset_custom).map(k => ({ propName: k + '_custom', label: k }))
      ]
    }
    return vars
  }, [categoricalFields, policy.segmentation_variables.dataset_custom])

  const fillLocalData = () => {
    setCategoricalFields(
      variables
        .filter(d => d.category === 'categorical')
    )

    setNumericalFields(
      variables
        .filter(d => d.category === 'numeric')
    )

    setFactorFields(
      variables
        .filter(d => d.category === 'factor')
    );

    setStatColumns(
      variables
        .filter(d => d.category === 'statistic')
    )

    if (policyType === 'normal') {
      setNotExistingAffectedVariables(
        policy.affected_variables.filter(av => !variables.find(v => v.propName === av.name)).map(av => ({ propName: av.name, label: av.name }))
      )
    }
  }

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step) => () => setActiveStep(step);

  const handleReset = () => setActiveStep(0);

  const handlePrioritizationFields = (e, newValue) => {
    if (e.target.name === 'prioritize_low') {
      policy.prioritization[0].prioritize_low = newValue === 'true'
    } else {
      policy.prioritization[0].name = e.target.value
    }

    handlePolicyChange(policy)
  }

  const onChangeAffectedVariable = (e, vars, varIndex, effect, effectIndex, autoValue = null) => {
    let affectedVariables = _.cloneDeep(policy.affected_variables)
    if (effect) {
      affectedVariables[varIndex].effects[effectIndex][e.target.name] = e.target.value
    } else if (autoValue) {
      affectedVariables[varIndex].name = autoValue
    } else {
      affectedVariables[varIndex][e.target.name] = e.target.value
    }

    policy.affected_variables = affectedVariables

    handlePolicyChange(policy)
  }

  const onAddAffectedVariable = () => {
    policy.affected_variables.push({
      name: '',
      effects: [{
        label: '',
        type: 'factor',
        value: '',
        isInput: false
      }]
    })

    handlePolicyChange(policy)
  }

  const onRemoveAffectedVariable = (index) => {
    policy.affected_variables.splice(index, 1)
    handlePolicyChange(policy)
  }

  const onAddAffectedVariableEffect = (affectedVariable, index) => {
    affectedVariable.effects.push({
      label: '',
      type: 'factor',
      value: '',
      isInput: false
    })

    policy.affected_variables[index] = affectedVariable
    handlePolicyChange(policy)
  }

  const onRemoveAffectedVariableEffect = (affectedVariable, varIndex, effectIndex) => {
    affectedVariable.effects.splice(effectIndex, 1)
    policy.affected_variables[varIndex] = affectedVariable
    handlePolicyChange(policy)
  }

  const updateSections = () => {
    if (targetCollection && targetCollection.sections && targetCollection.sections.length) {
      let sections = [];
      let activeCriteria = policy.prioritization[0];

      if (activeCriteria) {
        const sectionByCriteria = targetCollection.sections.find(d => {
          return d.variable === activeCriteria.name;
        });

        if (sectionByCriteria) {
          sections = sectionByCriteria.sections;
        }
      }

      setSections(sections);
    }
  }

  const onRegimesChange = (regimes, policy) => {
    let localPolicy = { ...policy }
    localPolicy.regimes = regimes

    handlePolicyChange(localPolicy)
  }

  const onStatisticsChange = (statistics, policy) => {
    policy.statistics = statistics

    handlePolicyChange(policy)
  }

  const handleOnSaveFormula = (e) => {
    if (e.name) {
      let custom = { [e.name]: e }
      let newPolicy = _.cloneDeep(policy)
      newPolicy.segmentation_variables.dataset_custom = { ...newPolicy.segmentation_variables.dataset_custom ? newPolicy.segmentation_variables.dataset_custom : [], ...custom }
      newPolicy.segmentation_variables.in_dataset.push(e.name + '_custom')
      handlePolicyChange(newPolicy)
    }
  }

  const handleOnSaveFormulaValue = (e, varIndex, effectIndex) => {
    let affectedVariables = _.cloneDeep(policy.affected_variables)
    affectedVariables[varIndex].effects[effectIndex]['value'] = e.value

    policy.affected_variables = affectedVariables

    handlePolicyChange(policy)
  }

  const handleKeyDown = (event) => {
    const { value } = event.target;
    if (value.length >= 80 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const addPrioritizationCriteria = () => {
    let emptyPrioritization = {
      name: '',
      prioritize_low: true,
      formula: '',
    }

    policy.prioritization.push(emptyPrioritization)
    handlePolicyChange(policy)
  }

  React.useEffect(() => {
    fillLocalData()
  }, [variables])

  React.useEffect(() => {
    updateSections()
    console.log(sections)
  }, [])

  React.useEffect(() => {
    if (policy.prioritization&&policy.prioritization.length>0 && policy.prioritization[0]?.name) {
      updateSections()
      console.log(sections)
    }
  }, [policy.prioritization])

  // Stepper Form Children
  const steps = [
    {
      label: 'Specify Policy Name',
      visible: true,
      description: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Name"
                  name="name"
                  defaultValue={policy.name}
                  placeholder="Name"
                  variant="outlined"
                  onChange={(e) => {
                    policy.name = e.target.value
                    handlePolicyChange(policy)
                  }}
                  inputProps={{ maxLength: 80 }}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Item>
          </Grid>
        </Grid>
      ),
      dataCy: 'step-policy-name',
    },
    {
      label: 'Define your prioritization criteria',
      dataCy: 'step-prioritization-criteria',
      visible: true,
      description:
        (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                <Grid container spacing={1}>
                  {policy.prioritization.length > 0 ? (
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          select
                          label="Prioritization"
                          value={policy.prioritization && policy.prioritization[0].name}
                          onChange={(e) => handlePrioritizationFields(e)}
                          name="prioritization">
                          {numericalFields.map((field) => (
                            <MenuItem key={field.propName} value={field.propName}>
                              {field.label ? field.label : field.propName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      <FormControl>
                        <RadioGroup row sx={{marginRight: '300px'}}
                          name="prioritize_low"
                          value={policy.prioritization && policy.prioritization[0].prioritize_low}
                          onChange={(e, newValue) => handlePrioritizationFields(e, newValue)}>
                          {groupItem.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item.value}
                              control={<Radio />}
                              label={item.label}
                              disabled={item.disabled && 'disabled'}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid container item xs={12} justifyContent='flex-end'>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => addPrioritizationCriteria()}
                        type="button"
                        data-cy='btn_eligibity'>
                        + {t('add_second_criteria')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Item>
            </Grid>
          </Grid>
        )
    },
    {
      label: 'Select affected variable',
      dataCy: 'step-affected-variable',
      visible: policyType === 'normal',
      description: (
        <Grid container spacing={1}>
          {policyType === 'normal' && policy.affected_variables.map((vars, varIndex) => (
            <Grid item xs={12} key={vars.name}>
              <Item>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item xs={11}>
                    <Autocomplete
                      label="Name"
                      name="name"
                      value={vars.name}
                      freeSolo
                      autoSelect
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      getOptionLabel={(option) => {
                        const allOptions = [...numericalFields, ...factorFields, ...statColumns, ...notExistingAffectedVariables, { propName: 'cost', label: 'cost' }]
                        const selectedOption = allOptions.find(o => o.propName === option)
                        if (selectedOption) {
                          return selectedOption.label ? selectedOption.label : selectedOption.propName
                        }

                        return option
                      }}
                      disabled={vars.name === 'cost'}
                      onChange={(e, value) => onChangeAffectedVariable(e, vars, varIndex, null, null, value)}
                      id="policy-affected-variables"
                      options={[...numericalFields, ...factorFields, ...statColumns, ...notExistingAffectedVariables, { propName: 'cost', label: 'cost' }].map(o => o.propName)}
                      renderInput={(params) => <TextField {...params} label="Name" name="name" variant="outlined" />}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {vars.name !== 'cost' && (
                      <IconButton size="small"
                        onClick={() => onRemoveAffectedVariable(varIndex)}>
                        <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography style={PolicyStyle.typographyEffectStyle}>Effects</Typography>
                  </Grid>
                  <Grid item xs={11} style={PolicyStyle.gridItemStyle}>
                    {vars.effects.map((effect, effectIndex) => (
                      <Grid container key={effectIndex} spacing={1} alignItems='center'>
                        <Grid item xs={3}>
                          <FormControl fullWidth variant="outlined">
                            <TextField
                              select
                              label="Type"
                              value={effect.type}
                              onChange={(e) => onChangeAffectedVariable(e, vars, varIndex, effect, effectIndex)}
                              name="type">
                              <MenuItem value={'factor'}>Factor</MenuItem>
                              <MenuItem value={'formula'}>Formula</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={effect.type === 'factor' ? 4 : 8}>
                          <FormControl fullWidth variant="outlined">
                            <TextField
                              id="effect-label"
                              label="Label"
                              name="label"
                              value={effect.label}
                              onChange={(e, value) => onChangeAffectedVariable(e, vars, varIndex, effect, effectIndex, value)}
                              variant="outlined"
                              inputProps={{ maxLength: 80 }}
                              onKeyDown={handleKeyDown}
                            />
                          </FormControl>
                        </Grid>

                        {effect.type === 'factor' ? (
                          <Grid item xs={4}>
                            <ToolTip title={effect.value} placement="bottom">
                              <FormControl fullWidth>
                                <TextField
                                  select
                                  label="Value"
                                  value={effect.value}
                                  onChange={(e) => onChangeAffectedVariable(e, vars, varIndex, effect, effectIndex)}
                                  variant="outlined"
                                  name="value">
                                  {factorFields.map(field => (
                                    <MenuItem value={field.propName}
                                      key={field.propName}>{field.label ? field.label : field.propName}</MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </ToolTip>
                          </Grid>
                        ) : (
                          <Grid item xs={11}>
                            <form>
                              <Typography style={PolicyStyle.typographyFormulaStyle}>
                                Formula Value
                              </Typography>
                              <div style={PolicyStyle.divFormulaStyle} />
                              <TextareaAutosize
                                style={PolicyStyle.textareaAutosizeStyle}
                                label="Formula Value"
                                type="value"
                                variant="outlined"
                                name='value'
                                required
                                value={effect.value}
                                onChange={(e) =>
                                  onChangeAffectedVariable(e, vars, varIndex, effect, effectIndex)
                                }
                              />
                              <div style={PolicyStyle.divStyle}>
                                <Grid item style={PolicyStyle.gridItemFormulaStyle}>
                                  <ModalDialogCalcFormulaBuilder
                                    onSaveFormula={(e) => handleOnSaveFormulaValue(e, varIndex, effectIndex)}
                                    variables={variables}
                                  />
                                </Grid>
                              </div>
                            </form>
                          </Grid>
                        )}



                        <Grid item xs={1}>
                          {(vars.name !== 'cost') ? (
                            <IconButton size="small"
                              onClick={() => onRemoveAffectedVariableEffect(vars, varIndex, effectIndex)}>
                              <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }} />
                            </IconButton>
                          ) : (
                            <>
                              {effectIndex > 0 && (
                                <IconButton size="small"
                                  onClick={() => onRemoveAffectedVariableEffect(vars, varIndex, effectIndex)}>
                                  <DeleteForeverTwoToneIcon style={{ color: '#fd7676' }} />
                                </IconButton>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                  </Grid>
                  <Grid item xs={4}>
                    <Button size="small" onClick={() => onAddAffectedVariableEffect(vars, varIndex)}>
                      <AddIcon /> Add effect
                    </Button>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          ))}
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onAddAffectedVariable()}
              type="button">
              + Add affected variable
            </Button>
          </Grid>
        </Grid>
      )
    },
    {
      label: 'Segmentation Criteria',
      dataCy: 'step-segmentation-criteria',
      visible: true,
      description: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Autocomplete
                multiple
                id="tags-outlined"
                disableCloseOnSelect
                options={segmentationVariablesOptions}
                getOptionLabel={(option) => option.label ? option.label : option.propName}
                filterSelectedOptions
                ChipProps={{
                  deleteIcon: (<CancelIcon style={{ color: '#fd7676' }} />)
                }}
                value={[
                  ...categoricalFields.filter(c => policy.segmentation_variables.in_dataset.includes(c.propName)),
                  ...Object.keys(policy.segmentation_variables.dataset_custom || []).map(k => ({ propName: k + '_custom', label: k }))
                ]}
                onChange={(e, values) => {
                  policy.segmentation_variables.in_dataset = values.map(v => v.propName)
                  handlePolicyChange(policy)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Segmentation attributes:"
                  />
                )}
              />

              <FormControl variant="outlined" sx={{width:'100%'}}>
                <TextField
                  style={{ marginTop: 10 }}
                  label="Maximum Tree Level"
                  name="max_depth"
                  value={policy.segmentation_variables.max_depth}
                  type={'number'}
                  variant="outlined"
                  onChange={(e) => {
                    policy.segmentation_variables.max_depth = e.target.value
                    handlePolicyChange(policy)
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid container item xs={12} justifyContent={'flex-end'}>
            <ModalDialogCalcFormulaBuilder
              isFromSegmentation={true}
              variables={variables}
              onSaveFormula={(e) => handleOnSaveFormula(e)} />
            <ModalDialogDragDrop
              variables={variables}
              segmentationVariables={policy.segmentation_variables.in_dataset}
              onDragAndDrop={(inDataset) => {
                policy.segmentation_variables.in_dataset = inDataset
                handlePolicyChange(policy)
              }} />
          </Grid>
        </Grid>
      )
    },
    {
      label: 'Regimes',
      dataCy: 'step-regimes',
      visible: policyType === 'normal',
      description: (
        <>
          {policyType === 'normal' &&
            <Regimes regimes={policy.regimes} affectedVars={affectedVars}
              onRegimesChange={(d) => onRegimesChange(d, policy)} />
          }
        </>
      )
    },
    {
      label: 'Add additional statistics',
      dataCy: 'step-additional-statistics',
      visible: true,
      description: (
        <Statistics
          statistics={policy.statistics}
          sections={sections}
          columns={statsVarOptions}
          variables={variables}
          onStatisticsChange={(statistics) => onStatisticsChange(statistics, policy)} />
      )
    },
    {
      label: 'Specify your budget',
      dataCy: 'step-budget',
      visible: true,
      description: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <FormControl variant="outlined" sx={PolicyStyle.formControlStyle}>
                <TextField
                  style={PolicyStyle.textFieldStyle}
                  label={t('budget')}
                  name="budget"
                  type='number'
                  value={policy.budget}
                  onChange={(e) => {
                    policy.budget = e.target.value;
                    handlePolicyChange(policy)
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  onKeyPress={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                  variant="outlined"
                />
                <TextField
                  label="Cost Bar Title"
                  name="cost_bar_title"
                  defaultValue={policy.cost_bar_title}
                  variant="outlined"
                  onChange={(e) => {
                    policy.cost_bar_title = e.target.value
                    handlePolicyChange(policy)
                  }}
                  inputProps={{ maxLength: 17 }}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Item>
          </Grid>
        </Grid>
      )
    },
    {
      label: 'Add a methodological description',
      dataCy: 'step-methodological-description',
      visible: true,
      description: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <CKEditor
                editor={ClassicEditor}
                data={policy.methodology}
                onChange={(event, editor) => {
                  policy.methodology = editor.getData();
                  handlePolicyChange(policy)
                }}
              />
            </Item>
          </Grid>
        </Grid>
      )
    }
  ];

  return (
    <Box sx={PolicyStyle.boxStyle}>
      <Stepper activeStep={activeStep} nonLinear orientation="vertical">
        {steps.filter(step => step.visible).map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              data-cy={step.dataCy}
              onClick={handleStep(index)}
              style={{ cursor: 'pointer' }}
              optional={
                index === 7
                  ? (<Typography variant="caption">Last step</Typography>)
                  : null
              }>
              {step.label}
            </StepLabel>
            <StepContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box>{step.description}</Box>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-start" sx={{marginBottom:'10px'}}>
                  <Button
                    color='secondary'
                    variant="contained"
                    onClick={handleNext}>
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    style={{ marginLeft: 6 }}
                    variant="contained"
                    disabled={index === 0}
                    onClick={handleBack}>
                    Back
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={PolicyStyle.paperStyle}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={PolicyStyle.buttonStyle}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
